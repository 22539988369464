// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $dark; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
}
%buttonHover {
	// Link Hover
	color: $secondary;
}
%buttonActive {
	// Link Active
	color: $secondary;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $primary; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	z-index: 1001;
	.row {
		.col {
			padding: 0;
		}
	}
	&.firstLevel {
		nav {
			overflow-y: auto;
			height: calc(100vh - 10.125rem);
		}
	}
	nav {
		@include ul {
			background: $navBg;
			
			&.navi {
				li {
					text-transform: none;
					border-bottom: 1px solid $primary;
					border-left: 1px solid $primary;
					
					@include navButtons {
						@include navButton;
						color: $light;
					}
					@include hasSub {
						@include sub {
							color: $light;
							background: $navBg;
							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
						background-color: darken($navBg, 6%);
						border-top: 1px solid $primary;
					}
				}
			}
		}
	}
	.branding {
		display: none;
	}
	.contactDataTop {
		background: $light;
		padding: 1em 1.125rem;
		p {
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		&.firstLevel {
			nav {
				height: auto;
				overflow: hidden;
			}
		}
		.row {
			align-items: flex-end;
			.col {
				padding: 0 1rem;
			}
		}
		nav {
			ul {
				background: transparent;
				height: auto;
				&.navi {
					li {
						color: $light;
						border-bottom: 0;
						border-left: 0;
						a {
							font-size: 14px;
							padding: 1rem 0.5rem;
						}
						span {
							font-size: 14px;
							padding: 1rem 0.5rem !important;
						}
						&.hasSub {
							.sub {
								background: $navBg;
								border: 1px solid $dark;
								.navBack {
									border-top: none;
									border-bottom: 1px solid $primary;
								}
							}
						}
					}
				}
			}
		}
		.contactDataTop {
			display: flex;
			justify-content: flex-end;
			margin-top: 0.5rem;
			padding: 0;
			background: transparent;
			margin-right: 0.7rem;

			p {
				display: inline-block;
				margin-left: 1rem;
				margin-right: 0.35rem;
				color: $light;

				&:nth-child(2) {
					padding: 0 0 0 3rem;
				}
			}
		}
		.branding {
			display: block;
			img {
				padding: 1em 1.125rem;
			}
		}
		&.navSmall {
			transition: 300ms all;
			background: $navBg;
			border-bottom: 1px solid $primary;
		}
	}
}

@include breakpoint(giant) {
	#navigation {
		nav {
			ul {
				&.navi {
					li {
						a {
							padding: 1em rem($baseGap);
							font-size: 1rem;
						}
						span {
							padding: 1em rem($baseGap) !important;
							font-size: 1rem;
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;
	.branding {
		width: 5rem;
	}
	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAddContainer {
	margin-left: 1.5rem;

	div {
		.branding {
			display: none;
		}
		.contactData {
			background: transparent;
			margin-bottom: 1.5rem;
			color: $light;

			@include breakpoint(large) {
				margin-bottom: 0;
			}
			
		}
	}
	.naviAdd {
		li {
			//text-transform: uppercase;
			border-bottom: 1px solid $border;
			a {
				color: $light;
				width: 100%;
				display: inline-block;
				padding: 0.5rem 0;
			}
			&:last-child {
				margin-right: 0;
				border-bottom: 0;
			}
			&:hover {
				a {
					color: $secondary;
					transition: 300ms all;
				}
			}
			&.active {
				a {
					color: $secondary;
				}
			}
		}
	}
	@include breakpoint(medium) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem;
		color: $light;

		div {
			display: flex;
			div.contactData {
				display: block;
				padding: 0;
			}
		}
		.naviAdd {
			display: flex;
			justify-content: flex-end;
			margin-left: 0rem;
			li {
				border-bottom: 0;
				margin-right: 1rem;
				a {
					color: $light;

					&:hover,
					&.active{
						color: $secondary;
					}
				}
			}
		}
	}
	@include breakpoint(large) {
		div {
			div.contactData {
				margin: 0 1rem;
			}
			.branding {
				display: block;
				width: 10rem;
				margin: 0 1rem;
			}
		}
		.naviAdd {
			margin: 0 1rem 0 0;
			li {
				a {
					font-size: rem(14px);
				}
			}
		}
	}
	@include breakpoint(giant) {
		div {
			div.contactData {
				margin: 0 2rem;
			}
			.branding {
				margin: 0 2rem;
			}
		}
		.naviAdd {
			margin: 0 2rem;
			li {
				a {
					font-size: 1rem;
				}
			}
		}
	}
}