
// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	&.lazyLoaded {
		background-size:cover
	}

	body.index & {
		height: 25rem;
		@include breakpoint(medium) {
		}
		@include breakpoint(large) {
			height: 100vh;
		}

		.branding {
			display: block;
			max-width: rem(200px);
			width: 100%;
			height: auto;
			margin: 0 auto;
			position: relative;
			z-index: 1;
		}

		picture {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 0;
			width: 100%;
			height: auto;
		}
		div.row {
			flex-direction: column;
			align-items: center;
			p.claim {
				background: rgba(0,0,0,0.5);
				text-align: center;
				padding: 0.5rem 1rem 1rem 1rem;
				position: absolute;
				z-index: 2;
				top: 6rem;
				@include breakpoint(tiny) {
					top: 9rem;
				}
				@include breakpoint(large) {
					padding: 1rem 3rem 2rem 3rem;
					top: 15rem;
				}
				@include breakpoint(giant) {
					padding: 3rem 5rem 4rem 5rem;
				}
				@include breakpoint(huge) {
					top: 20rem;
				}
				@include breakpoint(full) {
					top: 25rem;
				}
			}	
		}
		.swiper-container {
			height: 100%;
			.swiper-wrapper {
				.swiper-slide {
					display: flex;
					align-items: center;
					justify-content: center;
					background-size: cover;
					&.lazyLoaded {background-size:cover}

					&.one {@include responsiveImage('layout/headerslide/slideBeleuchtung.jpg', (tiny, medium, large), true);}
					&.two {@include responsiveImage('layout/headerslide/slideVideo.jpg', (tiny, medium, large), true);}
					&.three {@include responsiveImage('layout/headerslide/slideSignal.jpg', (tiny, medium, large), true);}
					
					div.claimContainer {
						position: absolute;
						display: flex;
						flex-direction: column;
						align-items: center;
						p.claim {
							text-align: center;
							padding: 0.5rem 1rem 0rem 1rem;
							position: relative;
							z-index: 2;
							@include breakpoint(large) {
								padding: 1rem 3rem 0rem 3rem;
							}
							@include breakpoint(giant) {
								padding: 3rem 5rem 0rem 5rem;
							}
						}
						a.btn {
							bottom: -5rem;
							z-index: 10;
							position: absolute;
							@include breakpoint(giant) {
								bottom: -8rem;
							}
						}
					}
					
				}
			}
			body.index &{
				margin-top: 0rem;
			}
		}
	}
	body:not(.index) & {
		height: 7rem;
        background-position: top center;
		@include responsiveImage('layout/bgHeader.jpg', (tiny, medium), true);
		&.lazyLoaded {
	        background-size: 40px;
	    }
	    &.isOnScreen {
            background-size: cover;
        }
		@include breakpoint(tiny) {
			background-position: 50% 9%;
		}
		@include breakpoint(medium) {
			height: 15rem;
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	overflow: hidden;

	body:not(.index) & {
		margin-top: 2rem;
		@include breakpoint(medium) {
			margin-top: 3rem;
		}
		@include breakpoint(large) {
			margin: 6rem 0;
		}
	}

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}
	.teaserArea {
		background: $light;
		border-bottom: 1px solid $medium;
		position: relative;
		@include breakpoint(medium) {
			padding: 2rem 0;
		}
		.teaserDiv {
			display: flex;
			justify-content: center;
			padding-top: 4rem;
			padding-bottom: 2rem;
			transition: 300ms all;
			position: relative;
			font-size: rem(20px);
			color: $dark;
			text-align:center;

			&:hover {
				// background-image: radial-gradient(darken($medium,15%) 0%, transparent 70%);
				transition: 300ms all;
				color: $secondary;

				&:before {
					transition: 300ms all;
					transform: scale(1.2);
				}
			}
			&:before {
				position: absolute;
				font-size:rem(30px);
				color: $secondary;
				transition: 300ms all;
				top: 2rem;
				@include breakpoint(medium) {
					font-size:rem(50px);
				}
			}
			&.audiotechnik {
				border-bottom: 1px solid $light;
				@extend .icon-speaker;
    			&:before {
					@extend .icon;
    			}
			}
			&.beleuchtungstechnik {
				border-bottom: 1px solid $light;
				@extend .icon-bulb;
    			&:before {
					@extend .icon;
    			}
			}
			&.netzwerktechnik {
				@extend .icon-network;
    			&:before {
					@extend .icon;
    			}
			}
			&.interaktivetafeln {
				
    			&:before {
    				//@extend .icon;

    				color:$secondary;
					content:url(../images/index/tablet-small.png);

					@include breakpoint(medium) {
						content:url(../images/index/tablet.png);
					}
    			}
			}
			a {
				text-align: center;
				font-weight: 700;
				font-size: rem(20px);
			}
			@include breakpoint(medium) {
				padding-top: 6rem;
				padding-bottom: 3rem;
				&.audiotechnik, &.beleuchtungstechnik {
					//border-right: 1px solid $medium;
					border-bottom: 0;
				}
			}
			@include breakpoint(large) {
				padding-top: 8rem;
				padding-bottom: 5rem;
				&:before {
					top: 4rem;
				}
			}
		}
	}
	.contentArea1 {
		.contentArea1Image {
			@include responsiveImage('index/audiotechnik-saal.jpg', (tiny, medium), true);
			&.lazyLoaded {
		        background-size: 40px;
		        background-position: center center;
		    }
		    &.isOnScreen {
	            background-size: cover;
	        }
		}
		@include breakpoint(large) {
			align-items: stretch;
			.cut-auto {
				padding-left: 0;
			}
			img {
				margin-bottom: 0;
			}
		}
	}
	.contentArea2 {
		.contentArea2Image {
			@include responsiveImage('index/beleuchtungMensa.jpg', (tiny, medium), true);
			&.lazyLoaded {
		        background-size: 40px;
		        background-position: center center;
		    }
		    &.isOnScreen {
	            background-size: cover;
	        }
		}
		@include breakpoint(large) {
			align-items: stretch;
			.cut-auto {
				padding-right: 0;
			}
		}
		img {
			margin-bottom: 0;
		}
	}
	.contentArea3 {
		// .videoContainer {
		// 	margin-bottom: 2rem;
		// }
		@include breakpoint(giant) {
			align-items: center;
			.cut-auto {
				padding-left: 0;
			}
		}

		.contentArea3Image {
			@include responsiveImage('index/bgBeleuchtungstechnik.jpg', (tiny, medium), true);
			&.lazyLoaded {
		        background-size: 40px;
		        background-position: center center;
		    }
		    &.isOnScreen {
	            background-size: cover;
	        }
		}
		@include breakpoint(large) {
			align-items: stretch;
			.cut-auto {
				padding-left: 0;
			}
			img {
				margin-bottom: 0;
			}
		}
	}
	.referenzArea {
		@include responsiveImage('index/bgSpotlight.jpg', (tiny, medium,large), true);
		&.lazyLoaded {
	        background-size: 40px;
	        background-position: center center;
	    }
	    &.isOnScreen {
            background-size: cover;
        }
		.row {
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
		.referenzen-slider {
			width: 100%;
			height: 100%;
			.swiper-wrapper {
				.swiper-slide {
					height: rem(200px);
					background-size: cover;
					&.one {@include responsiveImage('layout/referenzen-slider/ballroom.jpg', (tiny), true);}
					&.two {@include responsiveImage('layout/referenzen-slider/einrichtungshaus.jpg', (tiny), true);}
					&.three {@include responsiveImage('layout/referenzen-slider/house-light.jpg', (tiny), true);}
					&.four {@include responsiveImage('layout/referenzen-slider/kirche.jpg', (tiny), true);}
					&.five {@include responsiveImage('layout/referenzen-slider/polizeiakademie.jpg', (tiny), true);}
					&.six {@include responsiveImage('layout/referenzen-slider/Speaker.jpg', (tiny), true);}
					&.seven {@include responsiveImage('layout/referenzen-slider/speaker-balken.jpg', (tiny), true);}
					@include breakpoint(medium) {
						height: rem(300px);
					}
				}
			}
			.swiper-pagination {
				position: relative;
				margin-top: 1rem;
				.swiper-pagination-bullet-active {
				}
			}
		}
	}
	.serviceArea {
		@include responsiveImage('index/bgMischpult.jpg', (tiny, medium,large), true);
		&.lazyLoaded {
	        background-size: 40px;
	        background-position: center center;
	    }
	    &.isOnScreen {
            background-size: cover;
        }
		background-position: top;
		.row {
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
		@include breakpoint(medium) {
			background-position: 14% 15%;
		}
		@include breakpoint(huge) {
			.claim {
				margin-bottom: 5rem;
			}
		}
	}
	.contentGap {
		margin-top: 3rem;
		margin-bottom: 3rem;
	}
	.contentGapLeft {
		@include breakpoint(large) {
			margin-top: 3rem;
			margin-bottom: 3rem;
		}
		@include breakpoint(giant) {
			padding-left: 3rem;
		}
		@include breakpoint(huge) {
			padding-left: 6rem;
		}
	}
	.contentGapRight {
		@include breakpoint(large) {
			margin-top: 3rem;
			margin-bottom: 3rem;
		}
		@include breakpoint(giant) {
			padding-right: 3rem;
		}
		@include breakpoint(huge) {
			padding-right: 6rem;
		}
	}
	.ueberUnsImage {
		min-height: 10rem;
		@include responsiveImage('layout/ueber-uns/anlage-ueberuns.jpg', (tiny), true);
		&.lazyLoaded {
	        background-size: 40px;
	        background-position: center center;
	    }
	    &.isOnScreen {
            background-size: cover;
        }
	}
	.ueberUnsImage2 {
		min-height: 10rem;
		@include responsiveImage('layout/ueber-uns/beleuchtungLautsprecher.jpg', (tiny), true);
		&.lazyLoaded {
	        background-size: 40px;
	        background-position: center center;
	    }
	    &.isOnScreen {
            background-size: cover;
        }
		margin-bottom: 1rem;
	}
	.serviceImage {
		min-height: 10rem;
		@include responsiveImage('layout/service/lautsprecher.jpg', (tiny,large), true);
		&.lazyLoaded {
	        background-size: 40px;
	        background-position: center center;
	    }
	    &.isOnScreen {
            background-size: cover;
        }
	}
	.serviceImage2 {
		min-height: 10rem;
		@include responsiveImage('layout/service/anlage.jpg', (tiny), true);
		background-position: right;
		&.lazyLoaded {
	        background-size: 40px;
	        background-position: center center;
	    }
	    &.isOnScreen {
            background-size: cover;
        }
		margin-bottom: 1rem;
	}
	.modalGallery {
		img {
			margin-bottom: 1rem;
		}
	}
	.collapser {
		overflow: hidden;
		z-index: 1;
		margin-bottom: 1rem;
		background: $dark;
		&:before, &:after {
			z-index: 11;
		}
		p {
			z-index: 12;
			position: relative;
			max-width: 80%;
			font-size: rem(30px);
			color: $light;
			display: block;
			line-height: 1.2em;
			margin-bottom: 0;
			span {
				font-size: 20px;
				color: $secondary;
				display: block;
			}
			@include breakpoint(large) {
				font-size: rem(35px);
				span {
					font-size: rem(25px);
				}
			}
		}
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
	.dse-cols {
		@include breakpoint(large) {
			column-count: 2;
		}
	}
}

// FOOTER
//////////////////////////////

#footer {
	background: $dark;
	margin-top: 3rem;
	padding: 1rem 0;
	background-size: cover;
	@include responsiveImage('layout/videotechnikSaal.jpg', (tiny, medium,large), true);
	&.lazyLoaded {
        background-size: 40px;
        background-position: center center;
    }
    &.isOnScreen {
        background-size: cover;
    }
	p.claim {
		margin-bottom: 2rem;
		padding: 0.5rem 1rem 1rem 1rem;
	}
	@include breakpoint(large) {
		p.claim {
			padding: 1rem 3rem 2rem 3rem;
		}
	}
	@include breakpoint(giant) {
		p.claim {
			padding: 3rem 5rem 4rem 5rem;
		}
	}
}

//Other scripts
////////////////
.branding img{
	background: none;
}
body.cmsBackend {
	header,
	nav,
	footer,
	.contactDataTop,
	#navigation{
		display: none!important;
	}

	.gap {
		padding: 10rem 0;
	}
	header {
		display: none;
	}
	.referenzen-slider {
		min-height: 35rem;
		max-width: 80%;
		.swiper-wrapper {
			display: block;
			.swiper-slide {
				display: block;
			}
		}
	}
	.ewcms_img {
		img {
			width: auto;
		}
	}
}

body.faq, body.agb {
	 h2.collapser{
	    z-index: 12;
	     position: relative;
    max-width: 100%;

    font-size: 1.2rem;

   
    color: #fff;
    display: block;
    line-height: 1.2em;
    margin-bottom: 0;
    @include breakpoint(medium) {
		 font-size: 1.875rem;
	}

}
	}
   body.cmsBackend{
   	h2.collapser{
   		 color: black;
   	}
   }