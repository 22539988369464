/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 16px;

$baseLineHeight: 24px;

$h1Size: 33px;

$h2Size: 31px;

$h3Size: 30px;

$h4Size: 24px;

$h5Size: 18px;

$h6Size: 16px;

$mainFont: "Lato", sans-serif;

$displayFont: "Montserrat Regular", sans-serif;

$displayFontBig: "Montserrat Medium", sans-serif;

$iconFont: "icomoon";

// Project colors

$primary: #474747;
$secondary: #007cb3;
$light: #fff;
$medium: #f4f2f2;
$dark: #000000;
$border: #bbb;
$alert: #D85D00;

$baseGap: 18px;

$rowMaxWidth: 1620px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "×", 
	minus: "-", 
	angle-right: "❯", 
	plus: "+", 
	angle-up: "↑", 
	exclamation: "!", 
	speaker: ""\e908"", 
	bulb: ""\e90a"", 
	network: ""\e90b"", 
	tablet: ""\e900"", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
		cols: (6, 11),
	),
	tiny: (
		width: em(480px, $bpContext), 
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (4),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (3, 4, 6),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (2, 3, 4, 6, 8, 10),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (5, 6, 7, 10),
		prefix: (1),
	),
	huge: (
		width: em(1364px, $bpContext), 
	),
	full: (
		width: em(1520px, $bpContext), 
		cols: (9),
		prefix: (1),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
);

